import React from 'react'
import { Box, Grid, Typography } from '@mui/material'

import Anchor from '@ecm/ui/element/Anchor'
import CloudflareImage from '@ecm/ui/element/CloudflareImage'
import Color from '@ecm/ui/element/Color'
import DefaultLayout from '@ecm/ui/layout/Default'
import { LEGAL_ZOOM_VIRTUAL_MAIL_PAGE } from '@ecm/utils/legalZoomRedirects'

import headerIllustration from '/public/images/illustration/ecm-lz-rebranding.svg'
import transitionArrow from '/public/images/illustration/ecm-to-lz.svg'

export const HomePage = () => {
  return (
    <DefaultLayout seoPage="homepage" hideFinalCta hideHeader hideFooter>
      <Grid
        container
        flexDirection="column"
        alignItems="center"
        sx={{
          background: `linear-gradient(180deg, ${Color.smoke.normal} 50%, rgba(255, 255, 255, 0) 100%)`,
        }}
        p={{ lg: '4.5em 17em 3em 17em', sm: '2.75em', xs: '1.25em' }}
      >
        <Box
          borderRadius="16px"
          bgcolor={Color.white.normal}
          textAlign="center"
          boxShadow={`0px 10px 20px ${Color.slate.lightest}12`}
          p={{ lg: '3em 6.75em', md: '2em 5em', sm: '3em 2.4em', xs: '1.25em' }}
        >
          <CloudflareImage src={headerIllustration} />
          <Box p={{ md: '2em 0', xs: '1.25em 0 1.5em 0' }}>
            <CloudflareImage src={transitionArrow} />
          </Box>
          <Typography variant="h5" component="h3" textAlign="center" color={Color.saint.normal}>
            Earth Class Mail is now LegalZoom Virtual Mail
          </Typography>
          <Typography variant="p3" color={Color.saint.darker}>
            We’re thrilled to announce Earth Class Mail is now Virtual Mail from our parent company,
            LegalZoom. Rated the #1 trusted source* for virtual mail services, LegalZoom is here to
            support all your virtual mail and business address needs.
          </Typography>
          <Typography variant="p3" color={Color.saint.darker}>
            With LegalZoom Virtual Mail, customers receive all the benefits of secure and safe
            virtual mail services, including a business address, digitized mail, junk mail
            filtering, and more.
          </Typography>
          <Anchor
            href={LEGAL_ZOOM_VIRTUAL_MAIL_PAGE}
            variant="p3"
            color={Color.white.normal}
            bgcolor={Color.saint.normal}
            sx={{
              border: `1px solid ${Color.saint.normal}`,
              borderRadius: '4px',
              py: '8px',
              textAlign: 'center',
            }}
            my="2em"
            p="1em 2em"
            external
          >
            Go to LegalZoom
          </Anchor>
          <Typography variant="p3" color={Color.saint.darker}>
            Existing Earth Class Mail customer?{' '}
            <Anchor
              href="https://app.earthclassmail.com/"
              variant="p4"
              external
              fontSize="16px"
              underline="always"
            >
              Log in
            </Anchor>
          </Typography>
        </Box>
        <Typography
          variant="p4"
          color={Color.slate.lightest}
          fontSize="12px"
          mt="1.5em"
          textAlign="center"
        >
          *Based on survey of small business owners commissioned by LegalZoom (2023)
        </Typography>
      </Grid>
    </DefaultLayout>
  )
}

export default HomePage
